.App {
    background-color: #f4f4f4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* KOLOR NAGŁÓWKA, STOPKI I MENU */

header, footer, header .react-date-picker__wrapper, .offcanvas, .offcanvas .react-date-picker__wrapper {
    background-color: #0a381f !important;
}

header .react-date-picker__inputGroup__input, .react-date-picker__inputGroup__divider, .offcanvas .react-date-picker__inputGroup__input {
    color: white !important;
    border: none;
}

header .react-date-picker svg, .offcanvas .react-date-picker svg {
    stroke: white;
}

footer {
    background-color: #0a381f;
    color: white;
    width: 100%;
    bottom: 0;
}

footer p {
    margin: 0;
}

.table-hover tbody {
    cursor: pointer;
}

.between-departures {
    border-top-width: 0.1em;
    border-top-color: darkgrey;
}

.next-departure {
    border-top-color: red;
    border-top-width: 0.2em;
}

.pointer {
    cursor: pointer;
}

/* TrainDetailsModal */
.trainDetails {
    max-height: 80vh;
    overflow-y: auto;
}
